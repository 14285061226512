<template>
    <div>
        <StSection :header="$t('URBANISM_REGULATIONS_PICKER.SECTION_TITLE')" v-if="partialEdit">
            <template #body>
                <div class="mb-8">{{ helpText }}</div>
                <StButton
                    variant="primary"
                    :callback="showModal"
                >
                    {{ $t('URBANISM_REGULATIONS_PICKER.COMPLETE_BUTTON') }}
                </StButton>
                <ApplicationUrbanismRegulationPicker
                    v-if="isVisible"
                    @hide="hideModal"
                ></ApplicationUrbanismRegulationPicker>
            </template>
        </StSection>
        <TerritorialAtlas
            :realEstates="realEstatesList"
            showLayers
            ref="territorialAtlas"
            :isRealEstateTargetUsed="true"
        ></TerritorialAtlas>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'ApplicationUrbanismRegulation',
        components: {
            ApplicationUrbanismRegulationPicker: () => import('./ApplicationUrbanismRegulationPicker.vue'),
            TerritorialAtlas: () => import(/* webpackChunkName: "atlas" */ "@/modules/applications/components/territorial-atlas/TerritorialAtlasCard.vue"),
        },
        props: {
            partialEdit: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                isVisible: false,
            }
        },
        computed: {
            ...mapGetters({
                didAutocompleteRLUs: 'applications/form/didAutocompleteRLUs',
                realEstatesList: 'applications/form/realEstateTarget',
            }),
            helpText() {
                return this.$t(`URBANISM_REGULATIONS_PICKER.${this.didAutocompleteRLUs ? 'HELP_TEXT_COMPLETED' : 'HELP_TEXT_NOT_COMPLETED'}`);
            }
        },
        methods: {
            showModal() {
                this.isVisible = true;
            },
            hideModal() {
                this.isVisible = false;
            },
        },
    };
</script>
